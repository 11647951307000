#topbar {
    background: $red-primary;
    height: 40px;
    transition: all 0.5s;
    color: white;

    .contact-info {
        a {
            line-height: 1;
            color: white;
            transition: {
                duration: 0.3s;
            }
            //transition: 0.3s;

            &:hover {
                text-decoration: underline;
            }
        }

        i {
            padding: {
                right: 5px;
            }
            margin: {
                left:  15px;
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .social-links {
        a {
            color: white;
            padding-left: 15px;
            display: inline-block;
            line-height: 1px;
            transition: 0.3s;

            &:hover {
                text-decoration: underline;
            }

            i {
                padding: {
                    right: 5px;
                }
                margin: {
                    left: 15px;
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

#header {
    background: white;
    z-index: 997;
    padding: 15px 0;
    top: 40px;
    box-shadow: 0px 2px 15px rgba(0,0,0,0.1);
    transition: all 0.5s;

    .logo {
        margin: 0;
        padding: 0;

        img {
            max-height: 50px;
        }
    }
}

.topbar-scrolled {
    top: -40px;
}

.header-scrolled {
    top: 0 !important;
    padding: 15px;
}

.nav-menu{
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    > ul {
        display: flex;

        > li {
            position: relative;
            white-space: nowrap;
            padding: 10px 0 10px 28px;

            > a:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: -5px;
                left: 0;
                background: {
                    color: $red-primary;
                }
                visibility: hidden;
                width: 0px;
                transition: {
                    duration: 0.3s;
                    property: all;
                    timing-function: ease-in-out;
                    delay: 0s;
                }
            }
        }
    }

    a {
        display: block;
        position: relative;
        color: $gray-primary;
        font-size: 15px;
        font-weight: 600;
        padding: 0 3px;
        font-family: 'Open Sans', sans-serif;
        transition: {
            duration: 0.3s;
        }
    }

    a:hover:before, li:hover, .active > a:before {
        visibility: visible;
        width: 100%
    }

    a:hover, .active > a, li:hover > a {
        color: $red-primary;
    }

    .drop-down{
        ul {
            display: block;
            position: absolute;
            left: 26px;
            top: calc(100% + 30px);
            z-index: 99;
            opacity: 0;
            visibility: hidden;
            padding: 10px 0;
            background: white;
            box-shadow: 0px 0px 30px rgba(127,137,161,0.25);
            transition: {
                duration: 0.3s;
            }

            a {
                padding: 10px 20px;
                font-weight: 500;
                text-transform: none;
                color: $red-primary;
            }

            a:hover, .active > a, li:hover > a {
                color: $red-secondary;
            }

        }

        &:hover > ul {
            opacity: 1;
            top:100%;
            visibility: visible;
        }

        > a:after {
            content: "\f078";
            font-family: fontawesome;
            padding-left: 5px;
        }

        .drop-down {
            ul {
                top:0;
                left: calc(100% - 30px);
            }

            &:hover > ul {
                opacity: 1;
                top: 0;
                left: 100%;
            }

            > a {
                padding-right: 35px;

                &:after {
                    content: "\f054";
                    font-family: fontawesome;
                    position: absolute;
                    right: 15px;
                }
            }
        }
    }
}

/*
    Mobile
*/

//Button menu
.mobile-nav-toggle {
    position: fixed;
    right: 25px;
    top: 25px;
    z-index: 9998;
    border: 0;
    background: none;
    font-size: 2rem;
    outline: none !important;
    line-height: 1;
    cursor:pointer;
    text-align: right;
    transition: {
        duration: 0.4s;
        property: all;
        delay: 0s;
    }

    i {
        color: $red-primary;
    }
}

//Block menu
.mobile-nav {
    position: fixed;
    top: 60px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    z-index: 9999;
    background: white;
    opacity: 0;
    visibility: hidden;
    border-radius: 10px;
    padding: 10px 0;
    overflow: {
        y: auto;
    }
    transition: {
        duration: 0.2s;
        delay: 0s;
        timing-function: ease-in-out;
    }

    * {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    a {
        display: block;
        position: relative;
        color: $gray-primary;
        padding: 10px 20px;
        font-weight: 500;
        outline: none;
    }

    a:hover, .active > a, li:hover > a {
        color: $red-primary;
        text-decoration: none;
    }

    .drop-down {
        > a {
            padding-right: 35px;

            &:after {
                content: "\f078";
                font-family: fontawesome;
                padding-left: 10px;
                position: absolute;
                right: 15px;
            }
        }

        ul {
            display: none;
            overflow: hidden;
        }

        li {
            padding-left: 20px;
        }
    }

    .active {
        .drop-down {
            > a:after {
                content: "\f054"
            }
        }
    }
}

//Fondo del menu
.mobile-nav-overly {
    width: 100%;
    height: 100%;
    z-index: 9997;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(9,9,9,0.4);
    overflow: hidden;
    display: none;
    transition: {
        duration: 0.2s;
        timing-function: ease-in-out;
    }
}

.mobile-nav-active {
    overflow: hidden;

    .mobile-nav {
        opacity: 1;
        visibility: visible;
    }

    .mobile-nav-toggle i {
        color: white;
    }
}

@media (max-width: 992px){
    #header {
        padding: 18px;
        top:0;

        .logo {

        }
    }
}

@media (max-width: 1366px) {
    .nav-menu .drop-down .drop-down {
        ul {
            left: -170%;
        }

        &:hover > ul {
            left: -190%;
        }

        > a:after {
            content: "\f053";
        }
    }
}
