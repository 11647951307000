#products {
    .icon-box{
        text-align: center;
        border: 1px solid $border-ligth;
        padding: 80px 20px;
        background: white;
        width: 100%;
        transition: {
            duration: 0.3s;
            property: all;
            timing-function: ease-in-out;
        }

        .icon {
            transition: {
                duration: 0.3s;
                timing-function: ease-in-out;
            }
            margin: {
                top: 0;
                bottom: 20px;
                right: auto;
                left: auto;
            }

            i {
                color: $red-primary;
                font-size: 1.8rem;
                transition: {
                    duration: 0.3s;
                    timing-function: ease-in-out;
                }
            }
        }

        h4 {
            font-weight: 700;
            margin-bottom: 15px;
            font-size: 1.5rem;

            a {
                color: $gray-primary;
                transition: {
                    duration: 0.3s;
                    timing-function: ease-in-out;
                }
            }
        }

        p {
            line-height: 24px;
            font-size: 0.9rem;
            margin-bottom: 0;
        }

        &:hover {
            border-color: white;
            box-shadow: 0 0 25px 0 $red-bg;

            h4 {
                a {
                    color: $red-primary;
                }
            }

            .icon {
                i {
                    color: $red-primary;
                }
            }
        }
    }
}
