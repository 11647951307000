// Body
//$body-bg: #f8fafc;
$body-bg: white;

// Typography
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red-primary: #DB0016;
$red-secondary: #ED0016;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$gray-primary: #222222;
$gray-secondary: #737373;
$gray: #88868B;
$color-body: #444444;

//Background
$light-bg: #FCFCFA;
$dark-bg-1: #1E2121;
$light-bg-2: #676E63;

$red-bg: rgba(219,0,22,0.1);

$border-ligth: #deebfd;
