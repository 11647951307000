.about {
    .content {
        h3 {
            font-weight: 600;
            font-size: 26px;
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                display: flex;
                align-items: flex-start;
                margin-bottom: 35px;

                &:first-child {
                    margin-top: 35px
                }
            }

            i {
                background: white;
                box-shadow: 0px 6px 15px $red-bg;
                font-size: 24px;
                padding: 20px;
                margin-right: 15px;
                color: $red-primary;
                border-radius: 50px;
            }

            h5 {
                font-size: 1.12rem;
                color: #555555;
            }

            p {
                font-size: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
