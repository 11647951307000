#hero {
    width: 100%;
    //height: 85vh;
    position: relative;
    margin-top: 115px;
    background: {
        color: $body-bg;
        position: top left;
        size: cover;
    }

    &:before {
        content: "";
        background: rgba(255,255,255,0.5);
        position: absolute;
        bottom: 0;
        top:0;
        left: 0;
        right: 0;
    }

    .carousel-width {
        width: 10% !important;
    }

    .carousel-container {
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        //background: rgba(255,255,255,0.35);
        padding: {
            left: 10%;
            right: 50%;
        }

        .hero-caption {
            margin-top: 125px;
            padding: 10px 5px;

            img {
                max-height: 180px;
            }

            h1 {
                margin: 0;
                font-size: 3rem;
                font-weight: 700;
                line-height: 56px;
                color: $gray-primary;
                font-family: "Poppins", sans-serif;

                span {
                    color: $red-primary;
                }
            }

            h2 {
                //color: #555555;
                color: $color-body;
                margin: {
                    top: 5px;
                    left: 0;
                    bottom: 30px;
                    right: 25%;
                }
                font-size: 1.6rem;
                font-weight: 400;
            }

            .btn-action {
                font-family: "Roboto", sans-serif;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 0.9rem;
                letter-spacing: 1px;
                display: inline-block;
                padding: 10px 28px;
                border-radius: 4px;
                transition: 0.5s;
                color: white;
                background: $red-primary;
                margin-left: 20px;

                &:hover {
                    background: $red-secondary;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    #hero {
        .carousel-container {
            padding-right: 45% !important;
            .hero-caption {
                padding-top: 0px;
            }
        }
    }
}

@media (max-width: 1024px) {
    #hero {
        background-attachment: fixed;

        .hero-caption {
            margin-top: 50px !important;
        }
    }
}
@media (max-width: 796px){
    #hero {
        .carousel-container{
            .hero-caption{
                h2 {
                    display: none !important;
                }
                h1 {
                    margin-bottom: 15px !important;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    #hero {
        //height: 100vh;
        .carousel-container {
            .hero-caption{
                h1 {
                    font-size:28px;
                    line-height: 36px;
                }

                h2 {
                    font-size: 18px;
                    line-height: 24px;
                    margin-bottom: 30px;
                }

                .btn-action {
                    font-size: 0.8em;
                }
            }
        }
    }
}

@media (max-width: 500px){
    #hero {
        //height: 120vh;
    }
}
