.products-view {
    .item {
        margin-bottom: 20px;
        overflow: hidden;
        border-right: 4px;
        background: white;
        border: 1px solid $border-ligth;
        position: relative;
        transition: {
            duration: 0.2s;
            property: all;
            timing-function: ease-in-out;
        }

        .item-img {
            position: relative;
            overflow: hidden;
        }

        .item-info {
            padding: {
                top: 25px;
                bottom: 45px;
                right: 15px;
                left: 15px;
            }

            h4 {
                font-weight: 700;
                margin-bottom: 5px;
                font-size: 1.4rem;
                color: $gray-primary;
            }

            span {
                display: block;
                font-size: 0.9rem;
                font-weight: 400;
                color: #aaaaaa;
            }

            p {
                display: block;
                font-size: 1rem;
                line-height: 26px;
                color: #777777;
            }
        }

        a, button {
            display: block;
            //width: 100%;
            padding: 8px 0;
            text-align: center;
            background: $red-primary;
            color: white;
            position: absolute;
            right: 6%;
            left: 6%;
            bottom: 20px;

            &:hover {
                background: $red-secondary;
            }
        }

        &:hover {
            border-color: white;
            box-shadow: 0 0 25px 0 $red-bg;
        }
    }
}

.item-view {
    .item-title {
        font-size: 1.2em;
        font-weight: 700;
    }
    .item-info {
        padding: 25px 15px;

        h4 {
            font-weight: 700;
            margin-bottom: 5px;
            font-size: 1.4rem;
            color: $gray-primary;
        }

        p {
            margin: {
                left: 10px;
            }
            display: block;
            font-size: 1rem;
            line-height: 26px;
            color: #777777;
        }

    }
}

.btn-primary-red {
    display: block;
    width: 100%;
    padding: 8px 0;
    text-align: center;
    background: $red-primary;
    color: white;
    border: none;

    &:hover {
        background: $red-secondary;
        color: white;
    }
}

.pagination{
    .active{

    }
}
