#footer {
    background: #202020;
    padding-bottom: 30px;
    color: $gray;
    font-size: 0.9em;
    box-shadow: 2px ;

    .footer-newsletter {
        padding: 50px 0;
        background: $light-bg;
        text-align: center;
        font-size: 1em;

        h4 {
            font-size: 1.5em;
            margin: 0 0 20px 0;
            padding: 0;
            line-height: 1;
            font-weight: 600;
        }

        form {
            margin-top: 30px;
            background: white;
            padding: 6px 10px;
            position: relative;
            border-radius: 4px;
            box-shadow: 0px 2px 15px rgba(0,0,0,0.06);
            text-align: left;

            input[type="email"]{
                border:0;
                padding: 4px 8px;
                width: calc(100% - 100px);
            }

            input[type="submit"]{
                position: absolute;
                top:0;
                right: 0;
                bottom: 0;
                border: 0;
                font-size: 1em;
                padding: 0 20px;
                background: $red-primary;
                color: white;
                transition: 0.3s;
                border-radius: 0 4px 4px 0;
                box-shadow: 0 2px 15px rgba(0,0,0,0.1);

                &:hover {
                    background: $red-secondary;
                }
            }
        }
    }

    .footer-top {
        padding: 60px 0 30px 0;
        background: $gray-primary;

        h4 {
            color: rgba(255,255,255,0.8);
            text-transform: uppercase;
            font-weight: bold;
            position: relative;
            padding-bottom: 12px;
        }

        .footer-contact {
            margin-bottom: 30px;

            img {
                max-height: 150px;
            }

            p {
                font-size: 1.1em;
                line-height: 24px;
                margin-bottom: 0;
                font-family: "Roboto", sans-serif;
                color: rgba(255,255,255,0.6);
            }
        }

        .footer-links {
            margin-bottom: 30px;

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    padding: 8px 0;
                    display: flex;
                    align-items: center;

                    &:first-child{
                        padding-top: 0;
                    }
                }

                a {
                    color: rgba(255,255,255,0.6);
                    transition: 0.3s;
                    display: inline-block;
                    line-height: 1;

                    &:hover {
                        text-decoration: underline;
                        color: $gray;
                    }
                }
            }

            p {
                font-size: 1.1em;
                line-height: 24px;
                margin-bottom: 0;
                font-family: "Roboto", sans-serif;
                color: rgba(255,255,255,0.6);
            }
        }

        .social-links {
            a{
                font-size: 1.5rem;
                display: inline-block;
                color: rgba(255,255,255,0.6);
                line-height: 1;
                margin-right: 6px;
                transition: 0.3s;

                &:hover {
                    color: $gray;
                    text-decoration: none;
                }
            }
        }
    }

    .copyright{
        text-align: center;
        float: left;
    }

    .credits {
        float: right;
        text-align: center;

        a{
            color: $gray;
        }
    }

}

@media (max-width: 768px){
    #footer {
        .copyright, .credits {
            float: none;
            text-align: center;
            padding: 2px 0;
        }
    }
}
