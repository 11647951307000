
html {
    scroll-behavior: smooth;
}

a {
    color: $red-primary;
}

a:hover {
    color: $red-secondary;
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Roboto", sans-serif;
}

.mt-nav {
    margin-top: 115px !important;
}

section {
    padding: 60px 0;
    overflow: hidden;
}
.section-bg {
    background-color: $light-bg;
}
.section-title {
    text-align: center;
    padding-bottom: 30px;

    h2 {
        font-size: 0.8rem;
        letter-spacing: 1px;
        font-weight: 700;
        padding: 8px 20px;
        margin: 0;
        background: $red-bg;
        color: $red-primary;
        display: inline-block;
        text-transform: uppercase;
        border-radius: 50px;
    }

    h3 {
        font-size: 2rem;
        font-width: 700;
        margin: {
            top: 15px;
            bottom: 0;
            right: 0;
            left: 0;
        }

        span {
            color: $red-primary;
        }
    }

    p {
        margin: 15px auto 0 auto;
        font-weight: 600;
    }
}

#clients {
    img {
        max-height: 60px;
    }
}

.sales {
    padding: 60px 0;

    .item {
        margin-bottom: 20px;
        overflow: hidden;
        border-right: 4px;
        background: white;
        border: 1px solid $border-ligth;
        transition: {
            duration: 0.2s;
            property: all;
            timing-function: ease-in-out;
        }

        .item-img {
            position: relative;
            overflow: hidden;
        }

        .item-info {
            padding: 25px 15px;

            h4 {
                font-weight: 700;
                margin-bottom: 5px;
                font-size: 1.4rem;
                color: $gray-primary;
            }

            span {
                display: block;
                font-size: 0.9rem;
                font-weight: 400;
                color: #aaaaaa;
            }

            p {
                display: block;
                font-size: 1rem;
                line-height: 26px;
                color: #777777;
            }

            a {
                display: block;
                width: 100%;
                padding: 8px 0;
                text-align: center;
                background: $red-primary;
                color: white;

                &:hover {
                    background: $red-secondary;
                }
            }
        }

        &:hover {
            border-color: white;
            box-shadow: 0 0 25px 0 $red-bg;
        }
    }
}

.btn-whatsapp {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #25D366;
    color: #FFFFFF;
    height: 55px;
    width: 55px;
    text-align: center;
    border-radius: 50%;
    font-size: 2rem;
    z-index: 800;
    transition: all 300ms ease 0ms;
    box-shadow: 0px 18px 15px rgba(0,0,0,0.1);
}

@media (min-width: 1024px){
    .section-title{
        p {
            width: 50%;
        }
    }
}
